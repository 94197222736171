export const localRoute = {
  notFound: "/notfound",
  loginPage: "/lead/:token",
  dmPage: "/dm/:token",
  stepsPage: "/steps",
  carouselPage: "/carousel",
  navigationScreen: "/confirmation",
  navigationScreenNiyo: "/confirmationpage",
  stories: "/stories",
};
