import React from "react";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import IconButton from "@mui/material/IconButton";

import axios from "axios";
import "../../../App.css";
import "../../css/LoginForm.css";
import Zero from "../../assets/zero.svg";
import free from "../../assets/free.svg";
import percentage from "../../assets/percentage.svg";
import bottomDesign from "../../assets/bottom_design.svg";

import {
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import "../../css/LoginForm.css";
import Chip from "@mui/material/Chip";
import OTPModal from "../../Modal/OTPModal";
import { useEffect } from "react";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import niyoglobalMainImage from "../../assets/niyoglobalmainImg.png";
import { Stack } from "react-bootstrap";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    // "&.Mui-focused": {
    //   // backgroundColor: "transparent",
    //   // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    //   // borderColor: theme.palette.primary.main
    // }
  },
}));

const LoginFormNiyo = (props) => {
  const Mobile_Regex = new RegExp(/^[0]?[56789]\d{9}$/);
  console.log(props, "propsone");
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [loginData, setLoginData] = React.useState({});
  const [intTrav, setIntTrav] = React.useState(undefined);
  const [passport, setPassport] = React.useState(undefined);
  // const [numberError, setNumberError] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLogin = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "namevalue");
    setLoginData({ ...loginData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    console.log(loginData, "loginDatadata");
    console.log(intTrav, "loginDatadatainternational");
    console.log(passport, "loginDatadatainternationalPassort");
    console.log(props.data.api[0].api1, "props");
    localStorage.setItem("customer_number", loginData.customer_number);
    loginData.user_id = props.user_id;
    loginData.sow_id = props.sow_id;
    loginData.is_traveller = intTrav;
    loginData.has_passport = passport;
    const api = "https://app.backend.taskmo.co/niyoglobal/add_lead/stage1";
    axios
      .post(api, loginData)
      .then((res) => {
        if (res.data.error) {
          localStorage.setItem("token", "accepted");

          navigate("/confirmationpage");
        } else {
          handleClickOpen();
          localStorage.setItem("token", "rejected");
          console.log(res, "responseoutput");
        }
      })
      .catch((err) => console.log(err));
  };

  console.log(props.data.media, "askdjk");
  const handleclickIntYes = () => {
    setIntTrav("yes");
  };
  const handleclickIntNo = () => {
    setIntTrav("no");
  };

  const handlePassportYes = () => {
    setPassport("yes");
  };

  const handlePassportNo = () => {
    setPassport("no");
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid
          className="LoginFormMain"
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid
            item
            style={
              {
                // height: "70vh",
                // marginBottom: "300px",
                // overflowX: "scroll",
              }
            }
          >
            <Card
              // raised
              sx={{
                fontFamily: "Nunito",
                boxShadow: "none",
                marginTop: "40px",
              }}
            >
              <div
                className="cardMediaImagestyle"
                style={{ marginTop: "12px" }}
              >
                {/* <video
                  width="100%"
                  poster={props.data.media}
                  controls
                  autoPlay={true}
                >
                  <source
                    src={props.data.introduction_video}
                    type="video/mp4"
                  />
                  <source src="movie.ogg" type="video/ogg" />
                </video> */}
                <CardMedia
                  component="img"
                  image={niyoglobalMainImage}
                  src={niyoglobalMainImage}
                  className="cardMediaInsideImage"
                />
              </div>
              <Grid
                style={{
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  fontFamily: "Nunito",
                  fontWeight: "900",
                  width: "100%",
                }}
                container
                direction="column"
                justifyContent={"center"}
              >
                <Grid
                  item
                  style={{
                    fontWeight: "600",
                    fontSize: "27px",
                    color: "#1e3769",
                  }}
                >
                  Go Global with
                </Grid>
                <Grid
                  item
                  style={{
                    fontWeight: "900",
                    fontSize: "32px",
                    marginBottom: "20px",
                    color: "#1e3769",
                  }}
                >
                  Niyo Global
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems={"flex-start"}
                textAlign="center"
              >
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    alignItems="stretch"
                    textAlign={"center"}
                    justifyContent="center"
                  >
                    <Grid item>
                      <img src={Zero} />
                    </Grid>
                    <Grid
                      item
                      style={{
                        fontSize: "13px",
                        fontFamily: "Nunito",
                        width: "300px",
                        textAlign: "left",
                        color: "#1e3769",
                      }}
                    >
                      Zero Forex Markup* on all your international transactions
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    alignItems="stretch"
                    textAlign={"center"}
                    justifyContent="center"
                  >
                    <Grid item>
                      <img src={free} />
                    </Grid>
                    <Grid
                      item
                      style={{
                        fontSize: "13px",
                        fontFamily: "Nunito",
                        width: "300px",
                        textAlign: "left",
                        color: "#1e3769",
                      }}
                    >
                      Free International & Domestic airport lounge access* in
                      India
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    alignItems="stretch"
                    textAlign={"center"}
                    justifyContent="center"
                  >
                    <Grid item>
                      <img src={percentage} />
                    </Grid>
                    <Grid
                      item
                      style={{
                        fontSize: "12px",
                        fontFamily: "Nunito",
                        width: "300px",
                        textAlign: "left",
                        color: "#1e3769",
                      }}
                    >
                      5% interest* p.a. on savings with monthly interest payout
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                alignItems={"center"}
                justifyContent="center"
                style={{
                  width: "100%",
                  marginTop: "15px",
                  textAlign: "center",
                }}
                textAlign="center"
              >
                <img src={bottomDesign} />
              </Grid>

              <CardContent>
                {/* <Grid>
                  <Chip
                    label="Please fill below details to get register"
                    style={{
                      color: "#001DF8",
                      background: "#e7e9fc",
                      fontSize: "8px",
                      paddingRight: "14px",
                      fontFamily: "Nunito",

                      height: "20px",
                      fontSize: "10px",
                      marginTop: "-17px",
                      marginBottom: "10px",
                    }}
                  />
                </Grid> */}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <label
                      id="name"
                      style={{
                        color: "#292929",
                        fontFamily: "Nunito",
                        marginBottom: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Enter Your First Name
                    </label>
                    <TextField
                      style={{
                        // backgroundColor: "#f0f0f0",
                        fontFamily: "Nunito",
                        border: "0.4px solid #1e3769",
                        boxShadow: "none",

                        borderRadius: "4px",
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      id="name"
                      name="customer_first_name"
                      fullWidth
                      onChange={handleChangeLogin}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label
                      id="name"
                      style={{
                        color: "#292929",
                        fontFamily: "Nunito",
                        marginBottom: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Enter Your Last Name
                    </label>
                    <TextField
                      style={{
                        // backgroundColor: "#f0f0f0",
                        fontFamily: "Nunito",
                        border: "0.4px solid #1e3769",
                        boxShadow: "none",

                        borderRadius: "4px",
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      id="name"
                      name="customer_last_name"
                      fullWidth
                      onChange={handleChangeLogin}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label
                      id="name"
                      style={{
                        color: "#292929",
                        fontFamily: "Nunito",
                        marginBottom: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Enter mobile number
                    </label>
                    <TextField
                      type="number"
                      style={{
                        // backgroundColor: "#f0f0f0",
                        fontFamily: "Nunito",
                        border: "0.4px solid #1e3769",
                        borderRadius: "4px",
                        boxShadow: "none",
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      id="name"
                      variant="outlined"
                      error={
                        !Mobile_Regex.test(loginData.merchant_number)
                          ? true
                          : false
                      }
                      name="customer_number"
                      inputProps={{
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      fullWidth
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onChange={handleChangeLogin}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">+91-</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2} direction="column">
                      <Grid
                        item
                        style={{ fontFamily: "Nunito", fontWeight: "600" }}
                      >
                        Are you an International Traveller ?
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <div
                              type="text"
                              value={"Yes"}
                              name="is_traveller"
                              style={{
                                background: "#efefef",
                                borderRadius: "12px",
                                alignItems: "center",
                                textAlign: "center",
                                border: "none",
                                width: "60px",

                                background:
                                  intTrav == "yes" ? "#1E3669" : "#efefef",
                                color: intTrav == "yes" ? "#fff" : "#000",
                              }}
                              onClick={handleclickIntYes}
                            >
                              Yes
                            </div>
                          </Grid>
                          <Grid item>
                            <div
                              type="text"
                              value={"No"}
                              name="is_traveller"
                              style={{
                                background: "#efefef",
                                borderRadius: "12px",
                                alignItems: "center",
                                textAlign: "center",
                                border: "none",
                                width: "60px",

                                background:
                                  intTrav == "no" ? "#1E3669" : "#efefef",
                                color: intTrav == "no" ? "#fff" : "#000",
                              }}
                              onClick={handleclickIntNo}
                            >
                              No
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2} direction="column">
                      <Grid
                        item
                        style={{ fontFamily: "Nunito", fontWeight: "600" }}
                      >
                        Do you have passport ?
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <div
                              type="text"
                              value={"Yes"}
                              name="has_passort"
                              style={{
                                background: "#efefef",
                                borderRadius: "12px",
                                alignItems: "center",
                                textAlign: "center",
                                border: "none",
                                width: "60px",

                                background:
                                  passport == "yes" ? "#1E3669" : "#efefef",
                                color: passport == "yes" ? "#fff" : "#000",
                              }}
                              onClick={handlePassportYes}
                            >
                              Yes
                            </div>
                          </Grid>
                          <Grid item>
                            <div>
                              <div
                                onClick={handlePassportNo}
                                value="no"
                                style={{
                                  background: "#efefef",
                                  borderRadius: "12px",
                                  alignItems: "center",
                                  textAlign: "center",
                                  border: "none",
                                  width: "60px",

                                  background:
                                    passport == "no" ? "#1E3669" : "#efefef",
                                  color: passport == "no" ? "#fff" : "#000",
                                }}
                                variant="contained"
                                // onChange={handleChangeLogin}
                              >
                                No
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              zIndex: "10000",
            }}
          >
            <Box
              // m={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={"100%"}
              height="100px"
              style={{
                position: "fixed",
                bottom: 0,
                background: "#fff",
                display: open ? "none" : "",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                color="primary"
                sx={{ height: 50, width: "94%" }}
                style={{
                  background:
                    Object.values(loginData).length < 3 ||
                    Object.values(loginData)[2].length != 10 ||
                    !intTrav ||
                    !passport
                      ? "#c4c4c4"
                      : "linear-gradient(#8A7DD1, #643497)",
                  textTransform: "unset",
                  fontSize: "20px",
                  fontWeight: "600",
                  color:
                    Object.values(loginData).length < 3 ||
                    Object.values(loginData)[2].length != 10 ||
                    !intTrav ||
                    !passport
                      ? "#1d1d1d"
                      : "#fff",
                  fontFamily: "Nunito",
                }}
                disabled={
                  Object.values(loginData).length < 3 ||
                  Object.values(loginData)[2].length != 10 ||
                  !intTrav ||
                  !passport
                    ? true
                    : false
                }
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      {open && (
        <OTPModal
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          handleClose={handleClose}
          mbnumber={loginData.customer_number}
          api2={props.data.api[0].api2}
          sowIdcheck={props.sow_id}
        />
      )}
    </>
  );
};

export default LoginFormNiyo;
