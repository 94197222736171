import React from "react";
import { Route, Routes } from "react-router-dom";
import CarouselPage from "../pages/carouselpage/CarouselPage";
import ConfirmationPage from "../pages/ConfirmationPage";
import LoginPage from "../pages/loginPage/LoginPage";
import StepsPage from "../pages/stepspage/StepsPage";
import { Stories } from "../pages/Stories";
import { PrivateRoute } from "../privateroute/PrivateRoute";

import { localRoute } from "./localRoutes";
import ProtectedRoute from "../assets/ProtectedRoute.gif";
import ConfirmationPageNiyo from "../pages/ConfirmationPageNiyo";

const RouterComponent = () => {
  return (
    <div>
      <Routes>
        <Route path={localRoute.dmPage} element={<LoginPage />} />
        {/* <Route path={localRoute.stepsPage} element={<PrivateRoute />}>
          <Route path={localRoute.stepsPage} element={<StepsPage />} />
        </Route> */}
        {/* <Route path={localRoute.stepsPage} element={<PrivateRoute />}>
          <Route path={localRoute.carouselPage} element={<CarouselPage />} />
        </Route> */}
        {/* <Route path={localRoute.stepsPage} element={<PrivateRoute />}>
          <Route path={localRoute.stories} element={<Stories />} />
        </Route> */}
        {/* <Route path={localRoute.navigationScreen} element={<PrivateRoute />}> */}
        <Route
          path={localRoute.navigationScreen}
          element={<ConfirmationPage />}
        />
        {/* </Route> */}

        {/* <Route
          path={localRoute.navigationScreenNiyo}
          element={<PrivateRoute />}
        >
          <Route
            path={localRoute.navigationScreenNiyo}
            element={<ConfirmationPageNiyo />}
          />
        </Route>

        <Route path={localRoute.stories} element={<PrivateRoute />}>
          <Route path={localRoute.stories} element={<Stories />} />
        </Route>
        <Route
          path={localRoute.notFound}
          element={
            <div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "60vh" }}
              >
                <img src={ProtectedRoute} width="40%" height="30%" />
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  textAlign: "center",
                }}
              >
                Cannot Access Protected Route
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  textAlign: "center",
                }}
              >
                Please verify your Number to access this Page
              </div>
            </div>
          }
        /> */}
      </Routes>
    </div>
  );
};

export default RouterComponent;
