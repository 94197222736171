import React from "react";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import RouterComponent from "./components/routes/RouterComponent";

function App() {
  return (
    <div className="App">
      <RouterComponent />
    </div>
  );
}

export default App;
