import { Avatar, Button, Divider, Paper } from "@mui/material";
import React from "react";
import SecurityIcon from "@mui/icons-material/Security";
import Chip from "@mui/material/Chip";
import TaskmoIcon from "../assets/taskmologo.svg";
import Registration from "../assets/registration_success.gif";
import ShieldIcon from "../assets/shield-icon.svg";
import "../css/Confirmation.css";
import { useNavigate } from "react-router-dom";
import Plus from "../assets/plus.svg";

const ConfirmationPage = () => {
  const brandLogo = localStorage.getItem("brandlogo");
  const utmLink = localStorage.getItem("utmLink");
  console.log(brandLogo, "brandlogo");
  const navigate = useNavigate();
  return (
    <div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Avatar
          src={TaskmoIcon}
          sx={{ height: "70px", width: "70px" }}
          style={{ objectFit: "cover" }}
        />
      </div> */}
      <div className="abovegif">
        <img src={Registration} className="gifImagesuc" />
      </div>
      <div>
        <div
          className="belowgif"
          style={{ fontSize: "12px", fontWeight: "600" }}
        >
          Congratulations
        </div>
      </div>
      <div
        className="congmessg belowgif"
        style={{ fontSize: "11px", alignItems: "flex-end" }}
      >
        <div className="thanksregis">Thanks for registering on </div>

        <div style={{ marginTop: "10px" }}>
          <Avatar src={brandLogo} sx={{ height: "18px", width: "18px" }} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
