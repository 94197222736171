import React from "react";
import axios from "axios";
import Navbar from "../navbar/Navbar";
import "../../css/LoginPage.css";
import LoginForm from "./LoginForm";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { shareMedia } from "../../utils";
import { Interweave } from "interweave";
import somethingwentwrong from "../../assets/somethingwentwrong.gif";
import LoadingImage from "../../assets/loading.gif";
import LoginFormNiyo from "./LoginFormNiyo";
import NavbarNiyo from "../navbar/NavbarNiyo";
import LoginForm2 from "./LoginForm2";
import LoginForm3 from "./LoginForm3";
import LoginForm1361 from "./LoginForm1361";

const LoginPage = () => {
  const id = useParams();

  const { token } = id;

  const [decodedObj, setDecodedObj] = React.useState({});
  const [mediaData, setMediaData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [inputdatas, setInputdatas] = React.useState("");
  localStorage.setItem("token", "rejected");

  React.useEffect(() => {
    const tokenEnter = token;
    // const tokenEnter =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbnB1dERhdGEiOnsidXNlcl9pZCI6IjgxMyIsInNvd19pZCI6IjEzMDMifSwiaWF0IjoxNjY4ODQ1MDY4LCJleHAiOjE2Njk0NDk4Njh9.712qP0GjD1ycoqRZ_WCkZZ_hnf2-q2cs4n3P27NUOrA";
    const secret = "09f26e402586e2faa8da4c98a35f1b20d6b033c60...";
    const decoded = jwt_decode(tokenEnter, secret);
    console.log(decoded, "decoded");
    setDecodedObj(decoded.inputData);
    console.log(decoded, "decoded");
    let postData = decoded.inputData;
    localStorage.setItem("sowId", postData.sow_id);
    localStorage.setItem("brandlogo", postData.brand_logo);
    setInputdatas(postData);
    setIsLoading(true);
    axios
      .post(shareMedia, postData)
      .then((res) => {
        setIsLoading(false);
        console.log(res.data, "respavan");
        setMediaData(res.data);
        localStorage.setItem("brandlogo", res.data.brand_logo);
        localStorage.setItem("utmLink", res.data.utm_link);
      })
      .catch((err) => {
        console.log(err, "err");
        // setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token]);
  return isLoading ? (
    <>
      {" "}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "70vh" }}
      >
        <img src={LoadingImage} width="40%" height="30%" />
      </div>
      <div
        style={{ fontSize: "14px", fontFamily: "Nunito", textAlign: "center" }}
      >
        loading data
      </div>
      <div
        style={{ fontSize: "14px", fontFamily: "Nunito", textAlign: "center" }}
      >
        Please wait
      </div>
    </>
  ) : isError ? (
    <>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "70vh" }}
      >
        <img src={somethingwentwrong} width="40%" height="30%" />
      </div>
      <div
        style={{ fontSize: "14px", fontFamily: "Nunito", textAlign: "center" }}
      >
        Something Went Wrong.
      </div>
      <div
        style={{ fontSize: "14px", fontFamily: "Nunito", textAlign: "center" }}
      >
        Please Try Again Later
      </div>
    </>
  ) : (
    <div>
      <div className="navbarMain d-flex justify-content-center">
        {mediaData.project_id == "89" ? <NavbarNiyo /> : ""}
        {mediaData.project_id != "89" ? (
          <Navbar data={mediaData} user={decodedObj} />
        ) : (
          ""
        )}
      </div>
      <div>
        <div className="downNavbar">
          <LoginForm
            data={mediaData}
            campaign_id={inputdatas.campaign_id}
            sow_id={decodedObj.sow_id}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
